import React from "react"

import Shifts from "components/admin-shifts/shifts"
import { AdminLayout } from "components/layout-custom"

const Page = () => (
  <AdminLayout title="Shifts" padded={false}>
    <Shifts audience="admin" />
  </AdminLayout>
)

export default Page
