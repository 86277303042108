// @ts-strict-ignore
import React, { useEffect, useState } from "react"

import { format, isValid, startOfDay } from "date-fns"

import {
  Alert,
  AlertButton,
  Button,
  Heading,
  List,
  ListItem,
  Loading,
  Stack,
  Text,
} from "@kiwicom/orbit-components"
import {
  Bus,
  Coffee,
  Plus,
  Reload,
  SeatExtraLegroom,
} from "@kiwicom/orbit-components/icons"

import { parseErrorMessage } from "api/errors"

import { EmberDatePicker } from "components/generic/date-time/ember-date-picker"
import {
  ListTile,
  MultiColumnLayout,
} from "components/generic/multi-column-layout"

import {
  FlatShiftDetails,
  ShiftDetails as ShiftDetailsType,
} from "types/shift-detail"

import {
  formatAsTime,
  formatDateForQuery,
  getDefaultTimezone,
} from "utils/date-utils"
import { fetchFromAPIBase } from "utils/fetch-utils"
import { getPersonName } from "utils/name-utils"
import { useGlobalFetcher } from "utils/state-utils"
import { sortByKeys } from "utils/struct-utils"
import {
  updateQueryParam,
  updateStateFromUrl,
  useQueryString,
} from "utils/url-utils"

import ShiftDetails from "./shift-details"

interface ShiftTile {
  shift: FlatShiftDetails
  selected: boolean
  setSelected: (shift_id: number) => void
}

const ShiftTile = ({ shift, selected, setSelected }: ShiftTile) => {
  const start = formatAsTime(shift.scheduledStart, getDefaultTimezone())
  const end = formatAsTime(shift.scheduledEnd, getDefaultTimezone())
  return (
    <ListTile
      selectable
      selected={selected}
      onClick={(event) => {
        if (event.defaultPrevented) return
        setSelected(shift.id)
      }}
    >
      <Stack spacing="small">
        <Stack direction="row" justify="between" grow spacing="XSmall">
          <Heading type="title3">{`${start} - ${end}`}</Heading>
        </Stack>
        <Stack spacing="XXSmall">
          <Stack direction="row" justify="between" align="end">
            <List size="small">
              <Stack direction="row" shrink>
                <ListItem icon={<Bus />}>{shift.metadata.tripsCount}</ListItem>
                <ListItem icon={<Coffee />}>
                  {shift.metadata.breaksCount}
                </ListItem>
                <ListItem icon={<SeatExtraLegroom />}>
                  {shift.assignedDriver
                    ? getPersonName(shift.assignedDriver)
                    : "Unassigned"}
                </ListItem>
              </Stack>
            </List>
          </Stack>
        </Stack>
      </Stack>
    </ListTile>
  )
}

interface ShiftsProps {
  audience: "admin" | "driver"
}

const Shifts = ({ audience }: ShiftsProps) => {
  const [date, setDate] = useState<Date>(startOfDay(new Date()))
  const [shifts, setShifts] = useState<Array<FlatShiftDetails>>([])
  const [selectedShiftId, setSelectedShiftId] = useState(null)
  const [editingNewShift, setEditingNewShift] = useState(false)

  const [loadingShifts, setLoadingShifts] = useState(false)
  const [loadingShiftsError, setLoadingShiftsError] = useState(null)

  const { queryString } = useQueryString()
  const [loadedQueryString, setLoadedQueryString] = useState(false)

  const [shiftDetails, setShiftDetails] = useState<ShiftDetailsType | null>(
    null
  )
  const [loadingShiftDetails, setLoadingShiftDetails] = useState(false)
  const [loadingShiftDetailsError, setLoadingShiftDetailsError] = useState<
    string | null
  >(null)

  useGlobalFetcher("vehicles") // ensure we have a list of vehicles

  async function fetchShiftDetails() {
    setLoadingShiftDetails(true)
    setLoadingShiftDetailsError(null)
    if (selectedShiftId) {
      fetchFromAPIBase({
        path: `/v1/shifts/${selectedShiftId}/`,
        authRequired: true,
      }).subscribe((response) => {
        if (response.error) {
          setLoadingShiftDetailsError(parseErrorMessage(response))
        } else {
          setShiftDetails(response)
        }
        setLoadingShiftDetails(false)
      })
    }
  }

  useEffect(() => {
    if (selectedShiftId) {
      fetchShiftDetails()
    }
  }, [selectedShiftId])

  useEffect(() => {
    if (queryString != null && !loadedQueryString) {
      setLoadedQueryString(true)
      updateStateFromUrl(queryString, urlParams)
    }
  }, [queryString])

  useEffect(() => {
    if (loadedQueryString) {
      fullReloadShifts()
    }
  }, [date, loadedQueryString])

  const setDateFromQueryString = (dateString: string) => {
    const date = new Date(dateString)
    if (isValid(date)) {
      setDate(date)
    }
  }

  const handleSetDate = (newDate) => {
    setDate(newDate)
    updateQueryParam(queryString, {
      tag: "date",
      value: format(newDate, "yyyy-MM-dd"),
    })
  }

  const handleSelectShift = (newSelectedShiftId) => {
    newSelectedShiftId = parseInt(newSelectedShiftId)
    if (Number.isInteger(newSelectedShiftId)) {
      setSelectedShiftId(newSelectedShiftId)
      updateQueryParam(queryString, { tag: "shift", value: newSelectedShiftId })
    }
  }

  const urlParams = [
    {
      tag: "date",
      default: format(date, "yyyy-MM-dd"),
      setState: setDateFromQueryString,
    },
    {
      tag: "shift",
      setState: handleSelectShift,
    },
  ]

  const handleUnselectShift = () => {
    setSelectedShiftId(null)
    updateQueryParam(queryString, { tag: "shift", value: "" })
    setEditingNewShift(false)
  }

  // Generic load shifts function
  async function fetchShifts() {
    return new Promise<void>((resolve, reject) => {
      const query = new URLSearchParams({
        scheduled_start_time_from: formatDateForQuery(
          format(date, "yyyy-MM-dd")
        ).from,
        scheduled_start_time_to: formatDateForQuery(format(date, "yyyy-MM-dd"))
          .to,
        all_shifts: "true",
        flat_response: "true",
      }).toString()
      const sub = fetchFromAPIBase({ path: `/v1/shifts/?${query}` }).subscribe(
        (response) => {
          if (response.error) {
            reject(parseErrorMessage(response))
          } else {
            response as Array<FlatShiftDetails>
            setShifts(
              sortByKeys(response, [
                (s) => s.scheduledStart,
                (s) => s.scheduledEnd,
              ])
            )
            resolve()
          }
        }
      )
      return () => sub.unsubscribe()
    })
  }

  // Wraps fetch shifts function including handling state
  async function loadShifts() {
    setLoadingShifts(true)
    setLoadingShiftsError(null)
    await fetchShifts()
      .catch((error) => {
        setLoadingShiftsError(error)
        return
      })
      .finally(() => {
        setLoadingShifts(false)
      })
  }

  function fullReloadShifts() {
    setShifts(null)
    setShiftDetails(null)
    loadShifts()
  }

  function startCreateShift(): void {
    const newEmptyShift: ShiftDetailsType = {
      id: null,
      trips: [],
      activities: [],
      otherActivities: [],
      breaks: [],
      assignedDriver: null,
      scheduledStart: null,
      scheduledEnd: null,
      actualStart: null,
      actualEnd: null,
      payRate: 1,
      paidAsOvertime: false,
      adminOnlyNote: null,
      publicNote: null,
      metadata: null,
      assumedStart: new Date(date.setHours(1, 0, 0, 0)),
      assumedEnd: new Date(date.setHours(23, 59, 59, 0)),
    }

    updateQueryParam(queryString, {
      tag: "shift",
      value: null,
    })
    setSelectedShiftId(null)
    setShiftDetails(newEmptyShift)
    setEditingNewShift(true)
  }

  return (
    <MultiColumnLayout
      optionName="shifts"
      detailSelected={selectedShiftId != null || editingNewShift}
      unselectDetail={handleUnselectShift}
      sidebarHeader={
        <Stack direction="row" align="end" justify="between" grow>
          <EmberDatePicker date={date} setDate={handleSetDate} />
          <Stack direction="row" justify="end" shrink>
            <Button
              type="secondary"
              onClick={() => {
                startCreateShift()
              }}
              iconLeft={<Plus />}
            />
            <Button
              type="secondary"
              onClick={loadShifts}
              iconLeft={<Reload />}
            />
          </Stack>
        </Stack>
      }
      sidebarContent={
        loadingShifts ? (
          <Loading type="boxLoader" text="Loading shifts..." />
        ) : loadingShiftsError ? (
          <Alert type="critical" title="Could not load shifts">
            <Stack>
              <Text>
                Check you are logged in with the right permissions and your
                internet connection is active. The error message was "
                {loadingShiftsError}
                ".
              </Text>
              <AlertButton type="criticalSubtle" onClick={loadShifts}>
                Try again
              </AlertButton>
            </Stack>
          </Alert>
        ) : (
          shifts?.map((shift) => {
            return (
              <ShiftTile
                shift={shift}
                selected={shift.id === selectedShiftId}
                setSelected={handleSelectShift}
                key={`Shift ${shift.id}`}
              />
            )
          })
        )
      }
      detailSection={
        <ShiftDetails
          key={shiftDetails != null ? shiftDetails.id : 0}
          _shift={shiftDetails}
          _refreshShift={async () => {
            await fetchShiftDetails()
            await loadShifts()
          }}
          loadingShift={loadingShiftDetails}
          errorLoadingShift={loadingShiftDetailsError}
          audience={audience}
          setSelectedShiftId={setSelectedShiftId}
          handleUnselectShift={handleUnselectShift}
        />
      }
    />
  )
}

export default Shifts
